<template>
  <b-modal
    id="EducationalGroupStudentFreeze"
    scrollable
    centered
    hide-footer
    @shown="shown"
  >
    <PreLoader v-if="isLoading" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/user-cancel.svg" class="icon-lg" />
        {{ $t("general.makeFreezed") }}
      </h3>
    </template>

    <div class="row">
      <DateTimePicker
        class="col-md-12"
        :id="'EducationalGroupStudentFreeze-freezedToDateTime'"
        type="dateTime"
        :value="freezedToDateTime"
        :title="$t('JoiningApplicationEducationals.freezedTo')"
        v-on:changeValue="freezedToDateTime = $event.dateTime"
        :language="language"
      />

      <TextArea
        :className="'col-md-12'"
        :id="`EducationalGroupStudentFreeze-freezedNotes`"
        :value="freezedNotes"
        v-on:changeValue="freezedNotes = $event"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>

    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="makeFreezed"
      >
        {{ $t("ok") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('EducationalGroupStudentFreeze')"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "./../../../../components/general/PreLoader.vue";
import DateTimePicker from "./../../../../components/general/DateTimePicker.vue";
import TextArea from "./../../../../components/general/TextArea.vue";
import { STATUS } from "./../../../../utils/constants";
import { FREEZE_STATUS_TYPE } from "./../../../../utils/constantLists";
import { getLanguage, objectToFormData } from "./../../../../utils/functions";
import apiEducationalGroupStudent from "./../../../../api/educational/educationalGroupStudents";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    DateTimePicker,
    TextArea,
  },
  data() {
    return {
      language: getLanguage(),
      freezedToDateTime: null,
      freezedNotes: null,
    };
  },
  props: ["educationalGroupStudentToken"],
  computed: {},
  methods: {
    shown() {
      this.freezedToDateTime = null;
      this.freezedNotes = null;
    },
    async makeFreezed() {
      this.isLoading = true;
      let params = {
        educationalGroupStudentToken: this.educationalGroupStudentToken,
        freezeStatusToken: FREEZE_STATUS_TYPE.Freezed,
        freezedToDateTime: this.freezedToDateTime,
        freezedNotes: this.freezedNotes,
      };
      let formData = objectToFormData(params);
      try {
        const response = await apiEducationalGroupStudent.changeFreezeStatus(
          formData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("EducationalGroupStudentFreeze");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg, false);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>
